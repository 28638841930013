<script setup lang="ts">
  import thaiLogoLazy from '@/assets/images/thai_logo.webp?w=60&format=webp&imagetools';
  import thaiLogoHd from '@/assets/images/thai_logo.webp?w=220&format=webp&imagetools';
  import { mdiPhone } from '@mdi/js';

  function call() {
    window.open('tel:0897547618');
  }
</script>

<template>
  <v-app-bar :flat="true" height="120">
    <v-container class="py-0 px-4 px-sm-0 fill-height">
      <router-link
        to="/"
        class="d-flex text-decoration-none toolbar3-min-width float-left"
      >
        <v-img
          :src="thaiLogoHd"
          :lazy-src="thaiLogoLazy"
          alt="Thai Lemongrass Busselton Logo"
          width="120px"
          height="120px"
          class="ml-2"
        />
      </router-link>

      <v-spacer />
      <v-btn
        size="large"
        color="primary"
        class="text-uppercase"
        style="font-weight: 500"
        elevation="0"
        @click="call"
      >
        <template #prepend><v-icon :icon="mdiPhone" /></template>
        Book
      </v-btn>
      <v-btn
        size="large"
        color="primary"
        class="text-uppercase"
        style="font-weight: 500"
        elevation="0"
        @click="call"
      >
        <template #prepend><v-icon :icon="mdiPhone" /></template>
        Order
      </v-btn>
    </v-container>
  </v-app-bar>
</template>

<style scoped lang="scss"></style>
