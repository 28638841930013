<script setup lang="ts">
  import { call } from '@/utils';
  import momentTZ from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';
  import { useDisplay } from 'vuetify';
  import { computed } from 'vue';

  import { mdiCircleSmall, mdiPhone } from '@mdi/js';

  const { name } = useDisplay();

  const isSmall = computed<boolean>(() => {
    switch (name.value) {
      case 'xs':
      case 'sm':
        return true;
      case 'md':
      case 'lg':
      case 'xl':
      case 'xxl':
        return false;
    }

    return true;
  });
  function AWST() {
    return momentTZ.tz(momentTZ(), 'Australia/Perth').add(-0, 'h');
  }

  function active() {
    if (AWST().day() === 2) return false;
    if (AWST().isAfter(momentTZ('21:00:00', 'hh:mm:ss'))) return false;
    if (AWST().isBefore(momentTZ('11:30:00', 'hh:mm:ss'))) return false;
    if (
      AWST().isBetween(
        momentTZ('14:00:00', 'hh:mm:ss'),
        momentTZ('17:00:00', 'hh:mm:ss'),
      )
    )
      return false;
    return true;
  }
</script>

<template>
  <v-row>
    <v-col cols="12" md="6" :class="isSmall ? 'text-center' : 'text-left'">
      <div class="font-weight-black text-h6">
        OPENING TIMES
        <div class="d-inline-block ml-n2">
          <v-icon
            :icon="mdiCircleSmall"
            :color="active() ? '#AAFF00' : 'red'"
            size="40"
          />

          <v-tooltip activator="parent" aria-label="open now view">{{
            active()
              ? 'Open now! Call above'
              : 'Closed, please phone within the operating times'
          }}</v-tooltip>
        </div>
      </div>
      Open 6 Days
      <br />
      <strong>Lunch: </strong> 11:30am - 2:00pm
      <br />
      <strong>Dinner: </strong> 5:00pm - 9:00pm
      <br />
      Closed Tuesday
    </v-col>
    <v-col
      cols="12"
      md="6"
      class="text-left"
      :class="isSmall ? 'text-center' : 'text-right'"
    >
      <div class="font-weight-black text-h6">Prices subject to change</div>
      Drinks available
      <br />
      Dine in BYO (corkage charge applies)
      <br />
      Please phone to Book & order
      <br />
      <v-btn
        size="small"
        color="primary"
        elevation="0"
        style="font-weight: 500"
        @click="call"
      >
        <template #prepend><v-icon :icon="mdiPhone" /></template>
        08 9754 7618
      </v-btn>
    </v-col>
  </v-row>
</template>

<style scoped lang="scss"></style>
