<script setup lang="ts">
  import CopyrightMoco from '@/components/CopyrightMoco.vue';
  import Copyright from '@/components/Copyright.vue';
  import { onMounted, ref } from 'vue';
  import { mdiFacebook, mdiGoogle } from '@mdi/js';
  import FooterDisplay from '@/components/FooterDisplay.vue';

  const icons = ref([
    {
      link: 'https://www.facebook.com/ThaiLemongrassInBusselton/',
      icon: mdiFacebook,
      alt: 'facebook link',
    },
    {
      link: 'https://www.google.com/search?q=thai+lemongrass+busselton',
      icon: mdiGoogle,
      alt: 'google link',
    },
  ]);

  function openLink(link: string) {
    window.open(link, '_blank');
  }

  const showFooter = ref<boolean>(false);

  onMounted(() => {
    setTimeout(() => {
      showFooter.value = true;
    }, 500);
  });
</script>

<template>
  <v-footer
    v-if="showFooter"
    :absolute="true"
    class="text-center d-flex flex-column"
    height="auto"
    min-height="750px"
    width="100vw"
  >
    <v-container class="py-2">
      <div>
        <v-btn
          v-for="icon in icons"
          :key="icon.icon"
          class="mx-2"
          :icon="icon.icon"
          :aria-label="icon.alt"
          role="button"
          variant="text"
          @click="openLink(icon.link)"
        ></v-btn>
      </div>

      <FooterDisplay />
      <v-divider class="my-8"></v-divider>
    </v-container>
    <iframe
      src="https://www.google.com/maps/embed/v1/place?key=AIzaSyB7swX_qORNFXBjjmxEZMEoT11Zwe6pnvQ&q=Thai+Lemongrass+Busselton"
      defer
      style="
        border: 0;
        width: 100vw;
        height: 300px !important;
        filter: invert(90%) grayscale(1);
      "
      allowfullscreen="false"
      loading="lazy"
      referrerpolicy="no-referrer-when-downgrade"
      title="3/19 Bussell Highway, West Busselton, WA 6280"
      aria-label="3/19 Bussell Highway, West Busselton, WA 6280"
    ></iframe>
    <!--    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3321.1767778030708!2d115.33702897647973!3d-33.65258110789355!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2a2e3919c196e47b%3A0x7478dbc98119d12c!2sThai%20Lemongrass!5e0!3m2!1sen!2sau!4v1702010358975!5m2!1sen!2sau"-->

    <v-container class="py-2 mb-8">
      <v-divider class="my-8"></v-divider>
      <div class="text-center caption pb-2 font-weight-light">
        <Copyright />
      </div>
      <div class="text-center caption font-weight-medium">
        <CopyrightMoco />
      </div>
    </v-container>
  </v-footer>
</template>

<style scoped lang="scss"></style>
