import { createVuetify, ThemeDefinition } from 'vuetify';
import 'vuetify/styles';
import { mdiPhone } from '@mdi/js';
import { aliases, mdi } from 'vuetify/iconsets/mdi-svg'

const theme: ThemeDefinition = {
  dark: true,
  colors: {
    background: '#1c1a1b',
    surface: '#1c1a1b',
    primary: '#d7b128',
    'primary-darken-1': '#3700B3',
    secondary: '#03DAC6',
    'secondary-darken-1': '#018786',
    error: '#ff0000',
    'error-title': '#F51414',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FB8C00',
  },
};
export const vuetify = createVuetify({
  icons: {
    aliases: {
      ...aliases,
    },
    defaultSet: 'mdi',
    sets: {
      mdi,
    },
  },
  theme: {
    defaultTheme: 'theme',
    themes: {
      theme,
    },
  },
});
