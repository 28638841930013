<template>
  <div class="text-center text-uppercase">
    Copyright © {{ year }} Thai Lemongrass Busselton. All Rights Reserved. ABN
    81 158 885 771
  </div>
</template>
<script setup lang="ts">
  import { computed } from 'vue';

  const year = computed(() => {
    return new Date().getFullYear();
  });
</script>
